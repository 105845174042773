import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

// Adicionar rota
const RESOURCE = 'nf-generate';

export default {
  loadNfGenerates(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_NF_GENERATE', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeNfGenerate(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadNfGenerate(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}nf-generate/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateNfGenerate(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeNfGenerateSub(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateNfGenerateSub(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  destroyNfGenerateSub(context, { id, nfItens }) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}nf-itens-get-price/${id}`, nfItens)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  addItensNfGenerate(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}nf-generate-add-itens/${formData.id}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  getClientListNf(context) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}client-list-nf`)
        .then(response => {
          context.commit('LOADER', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  addGenerateNF(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}nf-generate-add-create/${id}`)
        .then(r => {
          return resolve(r.data);
        })
        .catch(err => {
          return reject(err);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  getGenerateNf(context, id) {

    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}nf-generate-get/${id}`)
        .then(r => {
          return resolve(r.data);
        })
        .catch(err => {
          return reject(err);
        })
        .finally();
    });
  },

  cancelNfGenerate(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}nf-generate-delete/${id}`)
        .then(r => resolve(r.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },


  getNfDownload(context) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
          .get(`${URL_BASE}nf-generate-get-xml`)
          .then(r => resolve(r.data))
          .catch(errors => reject(errors))
          .finally(() => context.commit('LOADER', false));
    });
  }



};
