<template>
    <button  class="btn btn-submit rounded-0 me-1" type="button">
        <i class="m-0 cloud download icon"></i>
    </button>
</template>

<script>
export default {
    name: "ButtonDownload",

    props: {

    },
}
</script>
<style scoped>
.btn-submit {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
  float: inline-end;
}

.btn-submit.disabled,
.btn-submit:disabled {
  opacity: 0.8;
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.btn-submit:active,
.btn-submit:focus,
.btn-submit:hover {
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}
</style>
