<template>

  <NFDownload id="1" v-if="nfModalDownload" @close="closeModalDownload" />



  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Nº:</label>
          <input-component v-model="formData.ref" placeholder="Número" />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Cliente:</label>
          <MultiSelect v-model="formData.client_id" :options="clients" placeholder="Cliente" />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectStatusGenerateNF" placeholder="Status" />
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar" />
          <button-route :items="itemsTwo" />


          <ButtonDownload @click="openModalDownload"/>


        </col-component>
      </row-component>

    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 20%">Nº</th>
                <th scope="col" style="width: 40%">Nome Cliente</th>
                <th scope="col" style="width: 40%">Status</th>
                <th scope="col" style="width: 20%">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Numero">{{ data.ref }}</td>
                <td class="row-consultora" data-label="Consultora" v-if="data.nameClient">
                  <div>
                    <span>{{ data.nameClient }}</span>
                    <br />
                    <img src="../../assets/avatar.png" class="user-photo" alt="Foto consultora" />
                  </div>
                </td>
                <td class="row-consultora" data-label="Consultora" v-else>
                  <div>
                    <span>N/I</span> <br />
                    <img src="../../assets/avatar.png" class="user-photo" alt="Foto consultora" />
                    {{ data.nameClient }}
                  </div>
                </td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações">
                  <button-edit :id="data.id" route="nf-generate-edit" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import { useToast } from 'vue-toastification';
import ButtonDownload from "@/components/buttons/ButtonDownload.vue";
import NFDownload from "@/components/modal/NFDownload.vue";

const toast = useToast();
export default {
  name: 'GenerateNFSearch',

  components: {
    NFDownload,
    ButtonDownload,
    ButtonPagination,
    ButtonEdit,
    ButtonRoute,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Gerar NF');
    this.checkACL();
    this.loadItems(1);
    this.loadClients();
  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'list alternate'
      },

      itemsTwo: {
        route: '/nf-generate/create',
        icon: 'plus',
        name: 'Adicionar'
      },

      buttonCheck: {
        create: false,
        edit: false
      },

      formData: {
        ref: '',
        client_id: '',
        status: ''
      },

      viewPage: false,
      searchSubmit: false,
      clients: [],
      nfModalDownload: false
    };
  },

  methods: {

    openModalDownload(){
      this.nfModalDownload =true
    },


    closeModalDownload(){
      this.nfModalDownload =false
    },

    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadNfGenerates', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 18)
        .then(r => {
          if (r.indexOf('nf-generate') !== -1) {
            this.viewPage = true;

            if (r.indexOf('nf-generate-search') !== -1) {
              this.viewPage = true;
            }

            if (r.indexOf('nf-generate-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.indexOf('nf-generate-edit') !== -1) {
              this.buttonCheck.edit = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Em Cadastro';
      if (parseInt(value) === 2) return 'Erro';
      if (parseInt(value) === 3) return 'Emitida';
      return 'Cancelada';
    },

    loadClients() {
      this.$store
        .dispatch('loadClientList')
        .then(r => {
          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon
          }));
        })
        .catch(e => {
          this.showError = true;
          window.scroll(0, 0);
          this.errors = e.response.data.errors;
        });
    },


  },

  computed: {
    selectStatusGenerateNF() {
      return this.$store.state.var.selectStatusGenerateNF;
    },

    object() {
      return this.$store.state.nf_generate.items;
    },

    params() {
      return {
        ref: this.formData.ref,
        client_id: this.formData.client_id,
        status: this.formData.status
      };
    }
  }
};
</script>

<style scoped>
@media (max-width: 991px) {
  .row-consultora {
    display: flex;
    justify-content: space-between;
  }

  .row-consultora div {
    display: flex;
  }

  .row-consultora div span {
    margin-top: 8px;
  }

  .row-consultora div img {
    margin-left: 10px;
  }
}
</style>
