<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError" />
    <!-- Modal de Consignado -->

    <div v-if="openModalConsigned" class="modal-overlay" @click.self="modalConsigned">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Selecionar Consignado</h4>
          <div role="button" @click="modalConsigned" class="btn-close"></div>
        </div>
        <div class="modal-body">
          <!-- <MultiSelect
            v-model="formData.consigned"
            placeholder="Selecionar Consignado"
            :options="formattedConsignedList"
            @change="selectedConsigned"
            class="custom-multiselect"
          /> -->
          <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div class="form-check form-check-inline">
                <input v-model="selectedDoc" type="radio" value="1" name="docs" id="type-one" />
                <label class="form-check-label" for="type-one">CPF</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="selectedDoc" type="radio" value="2" name="docs" id="type-two" checked />
                <label class="form-check-label" for="type-two">CNPJ</label>
              </div>
            </row-col-component>
          </row-component>

          <form @submit.prevent="getConsigned">
            <!-- Adicionando prevent para evitar recarregar a página -->
            <row-component>
              <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formDataConsigned.name" type="text" />
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Apelido:</label>
                <input-component v-model="formDataConsigned.surname" type="text" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                v-if="selectedDoc === '1'"
              >
                <label>CPF:</label>
                <input-component v-model="formDataConsigned.cpf" type="text" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                v-if="selectedDoc === '2'"
              >
                <label>CNPJ:</label>
                <input-component v-model="formDataConsigned.cnpj" type="text" />
              </col-component>

              <col-component class-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit class="me-1" type="submit" icon="search" />
                <!-- Tipo submit para indicar que é um botão de envio -->
              </col-component>
            </row-component>
          </form>

          <div class="mt-4">
            <row-component>
              <col-component
                class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                v-for="(data, index) in consignedList"
                :key="index"
              >
                <div class="d-flex align-items-center bg-body-secondary p-2 position-relative mt-2">
                  <div class="h-100 avatar__image__div">
                    <img class="avatar__image" v-if="data.photo" alt="" />
                    <img class="avatar__image" v-else src="@/assets/avatar.png" alt="" />
                  </div>
                  <div class="ms-3 w-75">
                    <p class="mb-1 text-truncate">{{ data.name }}</p>
                    <p class="mb-1">
                      {{ data.cnpj }} <br />
                      {{ data.cpf }}
                    </p>
                  </div>
                  <div class="position-absolute end-0 me-1 me-lg-3">
                    <button-submit icon="icon plus" @click="selectConsigned(data)" />
                  </div>
                </div>
              </col-component>
            </row-component>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>

    <form v-if="viewPage">
      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 consignado-col">
          <label>Consignado:</label>
          <div class="input-container">
            <input-component placeholder="Selecionar Consignado" :value="consignedName" :disabled="true" />
            <span class="icon-button" @click="modalConsigned()">
              <i class="icon search"></i>
            </span>
          </div>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 consignado-col">
          <label>Observações:</label>
          <div>
            <text-area v-model="formData.obs" rows="6" />
          </div>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="plus" name="Adicionar" />
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-route class="w-100" :items="itemsTwo" />
        </col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowComponent from '@/components/row/RowComponent';
import ColComponent from '@/components/row/ColComponent';
import InputComponent from '@/components/form/InputComponent';
// import MultiSelect from '@/components/form/MultiSelect';
import TextArea from '@/components/form/TextArea';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'ProductCreate',

  components: {
    PainelComponent,
    ErrorComponent,
    ColComponent,
    RowComponent,
    InputComponent,
    // MultiSelect,
    TextArea,
    ButtonRoute,
    ButtonSubmit
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Produto');
    this.checkACL();
  },

  data() {
    return {
      itemsOne: {
        title: 'Adicionar',
        icon: 'pen square'
      },

      itemsTwo: {
        route: '/acquisition/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      formData: {
        consigned_id: null,
        obs: '',
        consigned: ''
      },

      formDataConsigned: {
        name: '',
        surname: '',
        cpf: '',
        cnpj: ''
      },

      openModalConsigned: false,

      consignedList: [],

      sendForm: false,
      showError: false,
      viewPage: false,

      selectedDoc: '2'
    };
  },

  methods: {
    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('storeAcquisition', this.formData)
          .then(r => {
            this.showError = false;
            this.sendForm = false;
            toast.success('Cadastro efetuado com sucesso', { timeout: 1500 });
            this.$router.push({ name: 'acquisition-edit', params: { id: r } });
          })
          .catch(() => {
            this.showError = true;
            this.sendForm = false;
            window.scroll(0, 0);
          });
      }
    },

    modalConsigned() {
      this.openModalConsigned = !this.openModalConsigned;
      this.getConsigned();
    },

    getConsigned() {
      const params = {
        type: '',
        name: this.formDataConsigned.name,
        surname: this.formDataConsigned.surname,
        cnpj: this.formDataConsigned.cnpj,
        cpf: this.formDataConsigned.cpf
      };

      this.$store
        .dispatch('loadConsigneds', params)
        .then(response => {
          if (Array.isArray(response) && response.length > 0) {
            const consignedsData = response[0].data; // Altere isso se a estrutura for diferente
            this.consignedList = consignedsData;
          } else {
            this.consignedList = [];
          }
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1500 });
        });
    },

    selectedConsigned(selectedValue) {
      this.formData.consigned_id = selectedValue;
      this.modalConsigned();
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 22)
        .then(r => {
          if (r.indexOf('product-create') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    selectConsigned(consigned) {
      this.formData.consigned_id = consigned.id; // Armazena o ID do consignado selecionado
      this.openModalConsigned = false; // Fecha o modal
    }
  },

  computed: {
    formattedConsignedList() {
      return this.consignedList.map(consigned => ({
        label: consigned.name, // O que será exibido no MultiSelect
        value: consigned.id // O que será armazenado no v-model
      }));
    },

    object() {
      return this.$store.state.acquisition.items;
    },

    consignedName() {
      const consignado = this.object.data.find(item => item.id === this.formData.consigned_id);
      return consignado ? consignado.name : '';
    }
  }
};
</script>

<style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-container input-component {
  width: 100%;
}

.icon-button {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 3px 3px 9px;
  background-color: #000;
}

.icon-button .icon {
  font-size: 1.5em;
  color: #fff;
}

/* Estilos do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 900px;
  max-width: 90%;
  position: relative;
  max-height: 70%;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.btn-cancel {
  background-color: gray;
  border: 1px solid gray;
}

.btn-cancel:hover {
  background-color: rgb(112, 112, 112) !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

.custom-multiselect .multiselect-spacer {
  display: none; /* ou visibility: hidden; */
}
</style>
<!-- consigned_id -->
