<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError" />

    <div v-if="openModalSearchProduct">
      <AcquisitonProductSearch
        v-if="openModalSearchProduct"
        @close="closeModalClientProduct"
        :product-serach="formDataProduct"
        :id="id"
        @product-added="handleProductAdded"
      ></AcquisitonProductSearch>
    </div>

    <div v-if="openModalConsigned" class="modal-overlay" @click.self="modalConsigned">
      <div class="modal-content">
        <div class="modal-header">
          <h4></h4>
          <div role="button" @click="modalConsigned" class="btn-close"></div>
        </div>
        <!-- <div class="modal-body">
          <MultiSelect
            v-model="formData.consigned_id"
            placeholder="Selecionar Consignado"
            :options="formattedConsignedList"
            @change="selectedConsigned"
            class="custom-multiselect"
          />
        </div> -->
        <div class="modal-body">
          <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div class="form-check form-check-inline">
                <input v-model="selectedDoc" type="radio" value="1" name="docs" id="type-one" />
                <label class="form-check-label" for="type-one">CPF</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="selectedDoc" type="radio" value="2" name="docs" id="type-two" checked />
                <label class="form-check-label" for="type-two">CNPJ</label>
              </div>
            </row-col-component>
          </row-component>

          <form @submit.prevent="getConsigned">
            <!-- Adicionando prevent para evitar recarregar a página -->
            <row-component>
              <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formDataConsigned.name" type="text" />
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Apelido:</label>
                <input-component v-model="formDataConsigned.surname" type="text" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                v-if="selectedDoc === '1'"
              >
                <label>CPF:</label>
                <input-component v-model="formDataConsigned.cpf" type="text" />
              </col-component>

              <col-component
                class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                v-if="selectedDoc === '2'"
              >
                <label>CNPJ:</label>
                <input-component v-model="formDataConsigned.cnpj" type="text" />
              </col-component>

              <col-component class-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit class="me-1" type="submit" icon="search" />
                <!-- Tipo submit para indicar que é um botão de envio -->
              </col-component>
            </row-component>
          </form>

          <div class="mt-4">
            <row-component>
              <col-component
                class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                v-for="(data, index) in consignedList"
                :key="index"
              >
                <div class="d-flex align-items-center bg-body-secondary p-2 position-relative mt-2">
                  <div class="h-100 avatar__image__div">
                    <img class="avatar__image" v-if="data.photo" alt="" />
                    <img class="avatar__image" v-else src="@/assets/avatar.png" alt="" />
                  </div>
                  <div class="ms-3 w-75">
                    <p class="mb-1 text-truncate">{{ data.name }}</p>
                    <p class="mb-1">
                      {{ data.cnpj }} <br />
                      {{ data.cpf }}
                    </p>
                  </div>
                  <div class="position-absolute end-0 me-1 me-lg-3">
                    <button-submit icon="icon plus" @click="selectConsigned(data)" />
                  </div>
                </div>
              </col-component>
            </row-component>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>

    <div v-if="openModalUpdateStatus" class="modal-overlay" @click.self="modalUpdateStatus">
      <div class="modal-content">
        <div class="modal-header">
          <h4></h4>
          <div role="button" @click="modalUpdateStatus" class="btn-close"></div>
        </div>
        <div class="modal-body">
          <div class="mt-3">
            <row-component>
              <template v-for="(data, index) in getStatusOptions(formData.status)" :key="index">
                <col-component
                  class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                  v-if="parseInt(formData.status) !== data.value"
                >
                  <div class="d-flex align-items-center bg-body-secondary p-3 position-relative">
                    <div class="ms-1 w-75">
                      <p class="mb-0 text-truncate">{{ data.label }}</p>
                    </div>
                    <div class="position-absolute end-0 me-1 me-lg-3">
                      <button-submit
                        @click="submitStatus(data.value)"
                        class="mx-1"
                        :icon="'send'"
                        :disabled="isDisabled(data.value)"
                      />
                    </div>
                  </div>
                </col-component>
              </template>
            </row-component>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="pt-3 text-uppercase">
        <h5 class="text-dark">
          <span class="badge rounded-pill text-bg-dark">{{ statusString(formData.status) }}</span>
        </h5>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center justify-content-end m-3">
        <button-submit class="mx-1" :icon="'send'" @click="modalUpdateStatus(formData.status)" :toolip="Salvar" />
        <button-submit :icon="'print'" @click="openPDF(formData.urlPrint)" />
      </div>
    </div>

    <form v-if="viewPage">
      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 consignado-col">
          <label>Consignado:</label>
          <div class="input-container">
            <MultiSelect
              v-model="formData.consigned_id"
              placeholder="Selecionar Consignado"
              :options="formattedConsignedList"
              @change="selectedConsigned"
              class="custom-multiselect"
              :disabled="isDisabled"
            />
            <button-submit
              class="btn-modal-search"
              icon="search"
              @click="modalConsigned()"
              :disabled="isDisabledForm"
            />
          </div>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 consignado-col">
          <label>Observações:</label>
          <div>
            <text-area v-model="formData.obs" rows="6" :disabled="isDisabledForm" />
            <!-- Aqui -->
          </div>
        </col-component>
      </row-component>

      <row-component>
        <col-component
          v-if="formData.status === '1'"
          class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <button-submit :disabled="isDisabledForm" @click="submit" class="w-100" icon="save" name="Salvar" />
        </col-component>

        <col-component
          :class-col="
            formData.status === '1'
              ? 'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'
              : 'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
          "
        >
          <button-route class="w-100" :items="itemsTwo" />
        </col-component>
      </row-component>
    </form>

    <div class="space"></div>

    <button-submit @click="openModalSearchProduct = true" icon="plus" name="Produto" :disabled="isDisabledForm" />

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" style="width: 10%">Item</th>
                <th scope="col" style="width: 15%">Referência</th>
                <th scope="col" style="width: 10%">Foto</th>
                <th scope="col" style="width: 15%">Nome</th>
                <th scope="col" style="width: 12%">Valor UN</th>
                <th scope="col" style="width: 6%">UN</th>
                <th scope="col" style="width: 12%">Valor Total</th>
                <th scope="col" style="width: 10%">Remover</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in formData.product" :key="index">
                <td scope="col" data-label="Item">{{ index + 1 }}</td>
                <td scope="col" data-label="Referência">{{ data.ref }}</td>
                <td class="row-consultora" data-label="Foto">
                  <img class="rounded-circle" width="50" :src="data.photo" alt="" />
                </td>
                <td data-label="Nome">{{ data.name_product }}</td>
                <td data-label="Valor UN">R$ {{ data.price_un }}</td>
                <td data-label="UN">{{ data.amount }}</td>
                <td data-label="Valor Total">R$ {{ data.price_total }}</td>
                <td data-label="Remover">
                  <button-delete @click="destroyProduct(data)" :disabled="isDisabledForm" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row bg-black text-white py-2 mx-0 desc-price">
          <div class="col-2 px-2">Quantidade total: {{ formData.totalProductsItens }}</div>
          <div class="col-2 px-2">Total: {{ formData.totalProductsValor }}</div>
        </div>
      </div>
    </div>
  </painel-component>
</template>

<script>
import '@/assets/css/table.css';
import PainelComponent from '@/components/dashboard/PainelComponent.vue';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from '@/components/row/ColComponent.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import InputComponent from '@/components/form/InputComponent';
import MultiSelect from '@/components/form/MultiSelect.vue';
import TextArea from '@/components/form/TextArea';
import { Html5Qrcode } from 'html5-qrcode';
import AcquisitonProductSearch from '@/components/modal/AcquisitonProductSearch.vue';
import ButtonRoute from '@/components/buttons/ButtonRoute.vue';
import ButtonDelete from '@/components/buttons/ButtonDelete.vue';
import swal from 'sweetalert';

import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  name: 'AcquisitionUpdate',
  components: {
    PainelComponent,
    ErrorComponent,
    ButtonSubmit,
    RowComponent,
    ColComponent,
    MultiSelect,
    TextArea,
    AcquisitonProductSearch,
    ButtonDelete,
    ButtonRoute,
    InputComponent
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Editar');
    this.getConsigned();
    this.loadAcquisition();
    this.checkACL();
  },

  props: {
    id: {
      required: true
    }
  },

  mounted() {
    window.addEventListener('keydown', this.OnkeyDown);
  },

  data() {
    return {
      itemsOne: {
        title: 'Editar',
        icon: 'edit'
      },

      itemsTwo: {
        route: '/acquisition/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      formDateProduct: '',
      formData: {
        consigned_id: null,
        obs: ''
      },

      consignedList: [],

      openModalConsigned: false,

      viewPage: false,

      openModalSearchProduct: false,
      openModalUpdateStatus: false,

      config: {
        fps: 10,
        qrbox: { width: 500, height: 150 }
      },

      stopCam: false,

      formDataProduct: {
        barcode: '',
        barcodeReader: ''
      },

      formDataConsigned: {
        name: '',
        surname: '',
        cpf: '',
        cnpj: ''
      },

      showError: false,
      sendForm: false,
      selectedDoc: '2'
    };
  },

  methods: {
    OnkeyDown(e) {
      if (e.key == 'Enter') {
        if (this.formDataProduct.barcodeReader) this.setCodeBar(this.formDataProduct.barcodeReader);
        this.setTimeoutCodeBar();
        return;
      }

      if (e.key != 'Shift') this.formDataProduct.barcodeReader += e.key;

      this.setTimeoutCodeBar();
    },

    setTimeoutCodeBar() {
      setTimeout(() => {
        this.formDataProduct.barcodeReader = '';
        this.formDataProduct.barcode = '';
      }, 1000);
    },

    setCodeBar(code) {
      this.formDataProduct.barcode = code;
      this.openModalSearchProduct = true;
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateAcquisition', this.formData)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            window.scroll(0, 0);
            toast.success('Cadastro atualizado com sucesso', { timeout: 1500 });
          })
          .catch(e => {
            this.showError = true;
            this.sendForm = false;
            window.scroll(0, 0);
            this.errors = e.response.data.errors;
          });
      }
    },

    modalConsigned() {
      this.openModalConsigned = !this.openModalConsigned;
      this.getConsigned();
    },

    closeModalClientProduct() {
      this.openModalSearchProduct = false;
    },

    closeModalUpdateStatus() {
      this.openModalUpdateStatus = false;
    },

    loadAcquisition() {
      this.$store
        .dispatch('loadAcquisition', this.id)
        .then(r => {
          this.formData = r;
        })
        .catch(() => {});
    },

    getConsigned() {
      const params = {
        type: '',
        name: this.formDataConsigned.name,
        surname: this.formDataConsigned.surname,
        cnpj: this.formDataConsigned.cnpj,
        cpf: this.formDataConsigned.cpf
      };

      this.$store
        .dispatch('loadConsigneds', params)
        .then(response => {
          if (Array.isArray(response) && response.length > 0) {
            const consignedsData = response[0].data; // Altere isso se a estrutura for diferente
            this.consignedList = consignedsData;
          } else {
            this.consignedList = [];
          }
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1500 });
        });
    },

    selectedConsigned(selectedValue) {
      this.formData.consigned_id = selectedValue;
      this.openModalConsigned = false;
    },

    async checkACL() {
      this.$store
        .dispatch('ACLItems', 22)
        .then(r => {
          if (r.indexOf('product-edit') !== -1) {
            this.viewPage = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    openPDF(link) {
      if (link) {
        window.open(link, '_blank');
      }
    },

    getProduct() {
      this.$store
        .dispatch('getProduct', this.formData)
        .then(r => {
          this.formDateProduct = r;
        })
        .catch(() => {
          toast.warning(`Nenhum produto foi encontrado com referência: ${this.formData.code}`, {
            timeout: 6000
          });
        });
    },

    onCamera() {
      const html5QrCode = new Html5Qrcode('qr-code-full-region');
      const qrCodeSuccessCallback = decodedText => {
        html5QrCode.stop().then(() => {
          this.formData.code = decodedText;
          this.stopCam = false;
        });
      };
      this.stopCam = true;
      html5QrCode.start({ facingMode: 'environment' }, this.config, qrCodeSuccessCallback);
    },

    stopScan() {
      const html5QrCode = new Html5Qrcode('qr-code-full-region');
      html5QrCode.clear();
      this.stopCam = false;
    },

    destroyProduct(data) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente remover o produto ${data.name_product} referência ${data.ref}  ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store.dispatch('destroyProduct', data.id);
          this.loadAcquisition();
          toast.success('Produto removido com sucesso!', { timeout: 2000 });
          this.loadAcquisition();
        }
      });
    },

    handleProductAdded() {
      this.openModalSearchProduct = false;
      this.loadAcquisition();
    },

    modalUpdateStatus(status) {
      this.status = status;
      const statusMessages = {
        3: 'Status Finalizado, não é possível alterar o status',
        4: 'Status Cancelado, não é possível alterar o status'
      };
      if (statusMessages[status]) {
        toast.warning(statusMessages[status], { timeout: 1500 });
      } else {
        this.openModalUpdateStatus = !this.openModalUpdateStatus;
      }
    },

    getStatusOptions(status) {
      const options = {
        1: [
          { value: 2, label: 'Enviar consignação' },
          { value: 3, label: 'Finalizado' }
        ],
        2: [
          { value: 3, label: 'Finalizado' },
          { value: 4, label: 'Cancelado' }
        ]
      };
      return options[status] || [];
    },

    isDisabled() {
      const status = parseInt(this.formData.status);
      // Se o status for 2, 3 ou 4, desabilita os botões
      return status === 3 || status === 4;
    },

    submitStatus(status) {
      let params = {
        id: this.id,
        status: status
      };

      this.$store
        .dispatch('updateStatus', params)
        .then(() => {
          this.loadAcquisition();
          this.openModalUpdateStatus = false;
          if (parseInt(params.status) === 3) {
            toast.warning('Orçamento Finalizado, não é possível alterar o status', { timeout: 1500 });
          } else if (parseInt(params.status) === 4) {
            toast.warning('Orçamento Cancelado, não é possível alterar o status', { timeout: 1500 });
          } else {
            toast.success('Status atualizado com sucesso', { timeout: 1500 });
          }
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 6000 });
        });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Em cadastro';
      if (parseInt(value) === 2) return 'Em consignação';
      if (parseInt(value) === 3) return 'Finalizado';
      return 'Cancelado';
    },

    selectConsigned(consigned) {
      this.formData.consigned_id = consigned.id; // Armazena o ID do consignado selecionado
      this.openModalConsigned = false; // Fecha o modal
    }
  },

  computed: {
    formattedConsignedList() {
      return this.consignedList.map(consigned => ({
        label: consigned.name,
        value: consigned.id
      }));
    },

    consignedName() {
      if (!this.consignedList.length || !this.formData.consigned_id) return '';

      const consignado = this.consignedList.find(item => item.id === this.formData.consigned_id);

      return consignado ? consignado.name : '';
    },

    isDisabledForm() {
      return this.formData.status !== '1';
    }
  }
};
</script>

<style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-button {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  padding: 3px 3px 3px 9px;
  background-color: #000;
}

.icon-button .icon {
  font-size: 1.5em;
  color: #fff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 1200px;
  max-width: 90%;
  position: relative;
  max-height: 70%;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.btn-cancel {
  background-color: gray;
  border: 1px solid gray;
}

.btn-cancel:hover {
  background-color: rgb(112, 112, 112) !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

.custom-multiselect .multiselect-spacer {
  display: none; /* ou visibility: hidden; */
}

.space {
  background-color: #9fa2a3;
  width: 100%;
  height: 1px;
}

.products {
  color: #fff;
  background-color: #000;
  font-size: 1.2em;
  width: 92px;
  padding: 8px;
}

.input-select-photo-label {
  position: relative;
  height: 42px;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.input-photo-icone > i {
  display: none;
}

.large-input {
  width: 100%;
  padding: 10px;
}

.btn-search {
  background-color: #ebebeb;
  padding: 7px 5px 7px 9px;
  border: 1px solid #000;
}

.stop-cam-btn {
  height: 42px;
  width: 42px;
  text-align: center;
  padding: 0.4rem;
  outline: none;
  border: 0;
  background-color: #000;
  position: relative;
}

.stop-cam-btn > img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(105%) contrast(100%);
}

@media (max-width: 1024px) {
  .input-select-photo {
    display: none;
  }

  .input-select-photo-label {
    position: relative;
    height: 42px;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
  }

  .input-photo-icone {
    height: 42px;
    width: 42px;
    text-align: center;
    padding: 0.65rem;
    background-color: #000000;
    position: absolute;
    right: 2%;
    top: unset;
    bottom: 0;
  }

  .input-photo-icone > i {
    display: block;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(105%) contrast(100%);
  }
}

.btn-modal-search {
  padding-top: 9.5px;
  padding-bottom: 5px;
}

@media (max-width: 991px) {
  .row-consultora img {
    width: 35px;
    height: 35px;
  }
}
</style>
