<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <button type="button" class="btn btn-primary btn-submit" @click="getNFdownloadAPI"><i class="sync alternate icon"/></button>

      <div role="button" class="btn-close" @click="this.$emit('close')"></div>
      <br><br>

      <div class="__table">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" style="width: 30%">Mês</th>
              <th scope="col" style="width: 30%">Ano</th>
              <th scope="col" style="width: 20%">DANFE</th>
              <th scope="col" style="width: 20%">XML</th>
            </tr>
          </thead>
          <tbody>

          <tr v-for="(data, index) in formData" :key="index">
            <td >{{this.monthExtesno(data.mes.substring(4, 6))}}</td>
            <td >{{data.mes.substring(0, 4)}}</td>
            <td><a :href="data.danfes" target="_blank"><i class="download icon"></i> Baixar</a></td>
            <td><a :href="data.xmls" target="_blank"><i class="download icon"></i> Baixar</a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>




export default {
  name: 'NFDownload',
  components: {

  },

  created() {
    this.getNFdownloadAPI()
  },

  data(){
    return {

      formData:[{
        mes:'',
        xmls:'',
        danfes:''
      }],



    }
  },

  methods:{
    getNFdownloadAPI(){
      this.$store
          .dispatch('getNfDownload').then((r)=>{
            console.log(r)
        this.formData = r.original
      })
    },

    monthExtesno(mes){
      const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
      return meses[mes - 1];

    }
  }

  }
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 900px;
  max-width: 90%;
  position: relative;
  height: 500px;
  overflow-x:auto;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}



.input-photo-icone > img {
  display: none;
}



a{
  text-decoration: none;
  color:black;
  text-transform: uppercase;
}





.stop-cam-btn > img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(105%) contrast(100%);
}



.input-photo-icone > img {
  display: none;
}

@media (max-width: 1024px) {

  .input-photo-icone > img {
    display: block;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(105%) contrast(100%);
  }
}

.btn-submit {

  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
  width: 45px;
}

.btn-submit.disabled,
.btn-submit:disabled {
  opacity: 0.8;
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.btn-submit:active,
.btn-submit:focus,
.btn-submit:hover {
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}


</style>
