import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'entrance-and-exit';
export default {

    loadEntriesDepartures(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_ENTRANCE_EXIT', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => context.commit('LOADER', false))
        })
    },

    storeEntranceExit(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('LOADER', false))
        })
    },

    loadEntranceExit(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('LOADER', false))
        })
    },

    destroyEntranceExit(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('LOADER', false))
        })
    },


}
